import React from "react";
import { useState } from "react";
import "../styles/about.css";

function About() {
    const [selected, setSelected] = useState(null);
    const [selected1, setSelected1] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i);
    };
    const toggle1 = (i) => {
        if (selected1 === i) {
            return setSelected1(null);
        }
        setSelected1(i);
    };

    return (
        <div id="about" className="about-container">
            <div className="about-box">
                <h1>What is Software Freedom Fest?</h1>
                <p className="about-sff">
                    It's a worldwide celebration of open source and free
                    software. The purpose of this fest is to inform attendees
                    about the benefit of utilizing high-quality Open Source
                    software in all aspects of our daily lives. The appeal of
                    Open Source Software lies in the fact that it can be used by
                    anyone, anywhere for free. 15th May! mark the date, make
                    space in your calenders, we can't wait to see you on the
                    stage with us.
                </p>

                <div className="about-rows">
                    <div className="accordion">
                        {data1.map((item, i) => (
                            <div className="item">
                                <div
                                    className="title"
                                    onClick={() => toggle(i)}
                                >
                                    <h2>{item.question}</h2>
                                    <span>{selected === i ? "-" : "+"}</span>
                                </div>
                                <div
                                    className={
                                        selected === i
                                            ? "content-show"
                                            : "content"
                                    }
                                >
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="accordion">
                        {data2.map((item, i) => (
                            <div className="item">
                                <div
                                    className="title"
                                    onClick={() => toggle1(i)}
                                >
                                    <h2>{item.question}</h2>
                                    <span>{selected1 === i ? "-" : "+"}</span>
                                </div>
                                <div
                                    className={
                                        selected1 === i
                                            ? "content-show"
                                            : "content"
                                    }
                                >
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
const data1 = [
    {
        question: "Linux Conversion Day",
        answer: "Linux is an operating system, just like Windows, iOS, and Android. Many developers from all around the world used one of the most popular platforms on the planet. It's interesting to know that Android is a linux-based operating system. This is part of the software freedom fest, where participants can try out various Linux distributions. The hosts will also assist participants in setting up Virtual Machines using Linux software.",
    },
    {
        question: "Tech Cast Episodes",
        answer: "A series of live stream, where we have renowned tech personalities and enthusiasts join us to talk and engage with us about the latest trends in tech and share their experience in working with the tech space.This event focuses on embracing various domains and its involvement with Open Source.",
    },
];
const data2 = [
    {
        question: "WebHunt",
        answer: "The riddler is a supervillan appearing in the American comic books published by DC comics. Taking the first masked villan of Bataman as an  inspiration, we have come with a set of questions to test and find the best amongst the participants. Answer correctly and you shall be rewarded, and about failing to answer...well that's story for another day",
    },
    
];
export default About;
