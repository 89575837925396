import React from "react";
import "../styles/home.css";
import dicon from "../images/download.svg";

function Home() {
    return (
        <div className="bg">
            <div id="home" className="home-container">
                <h2>Software Freedom Fest</h2>
                <h3>Celebrating Software</h3>
                <div className="buttons">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdbqfoCMXHihwm-qlNLT-E-KgX1B0dhHRPZCcFz_cyszr60pQ/viewform?usp=send_form"
                    >
                        <button class="button button--mimas">
                            <span>Register now</span>
                        </button>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/1K9wyye_jfm5368TjDQFII3WyOUYC0puz/view"
                    >
                        <button class="button button--mimas" href="">
                            <span className="bflex">
                                Brochure &nbsp; <img src={dicon} />
                            </span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Home;
