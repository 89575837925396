import React from 'react';
import '../styles/navbar.css';
import logo from '../images/logo.svg'
import { useState } from 'react';
import{ Link as LinkS }from 'react-scroll'

function Navbar() {
  const [isOpen, setOpen] = useState(true);

  const toggleClass = () => {
    setOpen(!isOpen);
    if (document.body.style.overflow === "hidden") {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <div className="navbar_fix" >
      <nav className="navbar_main">
        <div className="logo">
          <a to="/"><img src={logo} alt="logo" /></a>
        </div>
        <ul className="nav_links">
          <li>
            <LinkS to="home" smooth={true} spy={true} exact="true" offset={-80} >Home</LinkS>
          </li>
          <li>
            <LinkS to="about" smooth={true} spy={true} exact="true" offset={-80} >About</LinkS>
          </li>
          <li>
            <LinkS to="timeline" smooth={true} spy={true} exact="true" offset={-80} >Timeline</LinkS>
          </li>
          <li>
            <LinkS to="sponsors" smooth={true} spy={true} exact="true" offset={-80} >Sponsors</LinkS>
          </li>
        </ul>

        <div
          className={isOpen ? "ham_but" : "ham_but active"}
          onClick={toggleClass}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>

      <aside className="nav_aside" style={isOpen ? { transform: "translateX(100%)" } : { transform: "translateX(0)" }} >
        <nav className="aside_nav">
          <ul className="nav_ul">
            <li style={isOpen ? { transform: "translate(0, 10vh)", opacity:0 } : { transform: "translate(0, 0)", opacity:1 }}>
              <LinkS to="home" smooth={true} spy={true} exact="true" offset={-80} >Home</LinkS>
            </li>
            <li style={isOpen ? { transform: "translate(0, 10vh)", opacity:0 } : { transform: "translate(0, 0)", opacity:1 }}>
              <LinkS to="about" smooth={true} spy={true} exact="true" offset={-80} >About</LinkS>
            </li>
            <li style={isOpen ? { transform: "translate(0, 10vh)", opacity:0 } : { transform: "translate(0, 0)", opacity:1 }}>
              <LinkS to="timeline" smooth={true} spy={true} exact="true" offset={-80} >Timeline</LinkS>
            </li>
            <li style={isOpen ? { transform: "translate(0, 10vh)", opacity:0 } : { transform: "translate(0, 0)", opacity:1 }}>
              <LinkS to="sponsors" smooth={true} spy={true} exact="true" offset={-80} >Sponsors</LinkS>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
}

export default Navbar;

