import React from 'react';
import '../styles/sponsors.css';
import CardContainer from './teams/CardContainer'
import './teams/sponsors.css'

function Sponsors() {
  return (
    <div id='sponsors' className='sponsors-container'>
      <h1 id='sponsorhead'>Sponsors</h1>
        <CardContainer/>
    </div>
  );
}

export default Sponsors;
