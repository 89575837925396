import React, { useState, useRef } from "react";
import starShip from "./../images/Star Ship.svg";
import logo from "./../images/logo.svg";
import { FaSafari, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import "../styles/footer.css";
import {
  useViewportScroll,
  motion,
  AnimatePresence,
  AnimateSharedLayout,
  useTransform,
  useMotionValue,
} from "framer-motion";
import oscLogo from "./../images/OSC_logo.png";
import nullLogo from "./../images/null_logo.png";
import wiosLogo from "./../images/WIOS_logo.png";
import "../styles/footer.css";
function Footer() {
  return (
    <footer className="FooterContainer">
      <div className="FooterInnerContainer">
        <img className="WsocLogo" src={logo} />
        <div className="FooterBottom">
          <div className="FooterClubs">
            <p className="ClubName">Open Source Comunity: VIT-AP</p>
            <div className="SocialIcons">
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.instagram.com/osc.vitap/"
              >
                <FaInstagram />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.youtube.com/channel/UCL7e5F8GJIkLDKiu5pv2NAw"
              >
                {" "}
                <FaYoutube />{" "}
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.linkedin.com/company/oscvitap/"
              >
                {" "}
                <FaLinkedinIn />{" "}
              </a>
            </div>
          </div>

          <div div className="FooterClubs">
            <p className="ClubName"> Null Chapter : VIT-AP </p>
            <div className="SocialIcons">
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.instagram.com/nullchapter"
              >
                {" "}
                <FaInstagram />{" "}
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.youtube.com/channel/UCpBb1zOZ8Kf4sb1DhwXeKfw"
              >
                {" "}
                <FaYoutube />{" "}
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.linkedin.com/company/null-vit-ap/"
              >
                {" "}
                <FaLinkedinIn />{" "}
              </a>
            </div>
          </div>

          <div className="FooterClubs">
            <p className="ClubName">Women in Open Source : VIT-AP</p>
            <div className="SocialIcons">
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.instagram.com/wios_vitap/?utm_medium=copy_link/"
              >
                <FaInstagram />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.youtube.com/channel/UCc7KEMTSZaCVq-vKF9vkwQw"
              >
                {" "}
                <FaYoutube />{" "}
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.linkedin.com/company/women-in-open-source-vit-ap/"
              >
                {" "}
                <FaLinkedinIn />{" "}
              </a>
            </div>
          </div>

          <div className="FooterClubs">
            <p className="ClubName">Otaku Haven : VIT AP</p>
            <div className="SocialIcons">
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.instagram.com/otakuhaven_vitap/"
              >
                <FaInstagram />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://www.youtube.com/channel/UCh4NGM3wD1AluvacToMtVLA"
              >
                {" "}
                <FaYoutube />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
