import React, { useState } from "react";
import Card from "./Card";
import GeeksForGeeksLogo from "../../images/GeeksforGeeks.png";
import TaskadeLogo from "../../images/Taskade.png";
import GiveMyCertLogo from "../../images/GMC.png";
import StickerMule from "../../images/StickerMule.png";

import "./card_container.css";

function CardContainer() {
    const [cards, setcard] = useState("");
    const sponsordata = [
        {
            imgurl: GeeksForGeeksLogo,
            redirect: "https://www.geeksforgeeks.org/",
        },
        {
            imgurl: TaskadeLogo,
            redirect: "https://www.taskade.com/",
        },
        {
            imgurl: GiveMyCertLogo,
            redirect: "https://givemycertificate.com/",
        },
        {
            imgurl: StickerMule,
            redirect: "https://mule.to/p3c9",
        },
    ];
    return (
        <div>
            <div className="main_sponsors">
                <div className="flex_container_sponsors">
                    {sponsordata.map((card) => (
                        <Card imgurl={card.imgurl} redirect={card.redirect} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CardContainer;
