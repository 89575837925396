import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/main.css';

import Home from '../components/Home';
import About from '../components/About';
import Timeline from '../components/Timeline';
import Sponsors from '../components/Sponsors';
import Footer from '../components/Footer';

import StarfieldAnimation from 'react-starfield-animation'

function Main() {
  return (
    <div>
      <div className='canvas'>

      <StarfieldAnimation
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          numParticles: '500'
        }}
        />
      </div>
      <Navbar/>
      <Home/>
      <About/>
      <Timeline/>
      <Sponsors/>
      <Footer/>
    </div>
  );
}

export default Main;
