import React from "react";
import'./card.css'

function card({imgurl , redirect}) {
  return (
    <div>
      <div className="container_sponsors">
      <a href= {redirect} target="_blank" rel="noopener noreferrer"  >
        <img
          src = {imgurl}
          />
          </a>
      </div>
    </div>
  );
}

export default card;
